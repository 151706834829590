@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scroll-snap-y {
    scroll-snap-type: y mandatory;
  }

  .scroll-snap-x {
    scroll-snap-type: x mandatory;
  }

  .align-snap-center {
    scroll-snap-align: center;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
}

body {
  background-color: #F3F4F6;
}